import React from 'react';
import Logo from '../images/icon.png';
import { Link } from "gatsby"

function Footer() {
    return (
        <div>

            <div className="footer-container tw-flex tw-flex-col sm:tw-flex-row">

                {/* <img src={Logo} height="20px" className='logo-img tw-mr-8 tw-mb-4 sm:tw-mb-0' /> */}

                <span className="logo tw-mr-8">
                    <Link to="/">Simbastack</Link>
                </span>

                <div className="tw-flex tw-mr-8 tw-flex-1 tw-mb-4 sm:tw-mb-0">
                    nj@simbastack.com
                </div>

                <div className="tw-flex tw-mr-8 tw-flex-1 tw-mb-4 sm:tw-mb-0">
                    +1 717 683 9393
                </div>

                <address className="tw-flex tw-mr-8 tw-flex-1 tw-mb-4 sm:tw-mb-0">
                    100 Pine St <br />
                    STE 1250 <br />
                    San Francisco, CA - 94111 <br />
                    USA
                </address>

                <ul className='tw-flex-1 tw-mb-4 sm:tw-mb-0'>
                    <li>
                        <a href="https://mirror.xyz/wipiway.eth" target="_blank">
                            Blog
                        </a>
                    </li>
                    <li>
                        <a href="https://twitter.com/wipiway" target="_blank">
                            Twitter
                        </a>
                    </li>
                    <li>
                        <a href="https://www.linkedin.com/company/wipiway/" target="_blank">
                            Linkedin
                        </a>
                    </li>
                </ul>

            </div>

            <div>
                &copy;  Simbastack, {new Date().getFullYear()}. All Rights Reserved.
            </div>
        </div>
    );
}

export default Footer;
